/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/clipboard@2.0.11/dist/clipboard.min.js
 * - /npm/dom7@2.1.2/dist/dom7.min.js
 * - /npm/swiper@4.5.1/dist/js/swiper.min.js
 * - /npm/vue@2.5.22/dist/vue.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
